.footer{
  @apply flex;
  @apply w-full;
  @apply px-12;
  @apply py-12;
  // @apply bg-blue-600;
  @screen sm{
    @apply block;
    @apply px-6;
    @apply py-8;
  }
  .block{
    @apply w-1/3;
    @apply text-white;
    @screen sm{
      @apply w-full;
    }
    h3{
      @apply font-bold;
      @apply text-lg;
    }
    ul{
      li{
        @apply pt-4;
        @screen sm{
          @apply pt-3;
        }
        a{
          @apply text-yellow-500;
        }
        svg{
          @apply inline-block;
          @apply mr-2;
        }
      }
    }
    &.logo-block{
      svg{
        width:100%;
        max-width: 200px;
        @apply ml-auto;
        @screen sm{
          @apply pt-12;
          width:60px;
          @apply mx-auto;
        }
      }


    }
    &.ig-feed{
      @apply grid;
      @apply grid-cols-4;
      @apply gap-4;
      @screen sm{
        @apply mt-10;
      }
      a{
        // @apply w-1/4;
        @apply block;
        img{
          @apply w-full;
          height: auto;
          @apply block;
        }
      }
    }
  }

}
