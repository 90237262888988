.notification-main{
  @apply w-full;
  .notification-container{
    @apply px-12;
    @apply py-8;
    @apply text-center;
    span{
      @apply text-2xl;
      @apply font-bold;
      font-family: 'Merriweather', ;
      @screen sm{
        @apply text-xl;
      }

    }
  }
}

.about-block{
  @apply w-full;
  .about-content{
    @apply px-12;
    @apply py-8;
    @apply text-white;
    @apply text-center;
    @screen sm{
      @apply px-6;
      @apply py-8;
    }
    h1{
      @apply text-5xl;
      @apply font-bold;
      @apply italic;
    }
    p{
      @apply text-lg;
    }
  }
}
.block-container{
  @apply flex;

  @screen sm{
    @apply flex-wrap;
  }
  .block{

    h4{
      @apply font-bold;
      @apply text-xl;
      @apply mb-4;
      @apply text-blue-500;
      @screen sm{
        @apply text-lg;
      }
    }
    @apply w-1/3;
    @apply mx-4;
    @apply p-8;
    border: solid 1px #eee;
    @apply rounded;
    @screen sm{
      @apply w-full;
      @apply mb-4;
      @apply mx-0;
    }
    ul{
      @apply ml-6;
      li{
        @apply text-lg;
        @apply relative;
        @screen sm{
          @apply text-base;
        }
        @apply mb-2;
        &:before{
          content: '';
          @apply absolute;
          @apply h-2;
          transform: translateY(-1.5px);
          @apply w-2;
          @apply inline-block;
          @apply bg-yellow-500;
          @apply rounded;
          top: 0.65rem;
          // left: -20px;
          @apply -ml-6;
          @apply mr-3;
        }
      }
    }
  }
}
