.contact{
  p.header{
    @apply text-xl;
    @screen sm{

    }
  }
  .static-contact{
    ul{
      @apply mt-2;
      li{
        @apply mt-2;
        svg{
          @apply inline-block;
          @apply mr-2;
        }
      }
    }
  }
  .contact-component{
    form{
      @apply mt-2;
      label{
        @apply text-base;
        @apply block;
        @appy w-full;
      }
      .note{
        @apply text-sm;
        @apply italic;
      }
      input{
        @apply block;
        @apply mb-3;
        @apply px-6;
        @apply w-full;
        border: solid 2px #000;
        height:50px;
        @apply text-xl;
      }
      select{
        -moz-appearance: none;
      	-webkit-appearance: none;
      	appearance: none;
        @apply block;
        @apply mb-3;
        @apply px-6;
        @apply w-full;
        border: solid 2px #000;
        height:50px;
        border-radius: 0;
        background: #fff;
        @apply relative;
        &:invalid { color: gray; }

      }
      textarea{
        @apply block;
        @apply mb-3;
        @apply px-6;
        @apply py-2;
        @apply w-full;
        border: solid 2px #000;

      }
      .form-row{
        @apply relative;
        &.select{
          &:after {
            content: '';
            position: absolute;
            right: 15px;
            bottom:17px;
            width: 0px;
            height: 0px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 10px solid #000;

            clear: both;

          }
        }
        &.error-message{
          color:#cc0000;
        }
        input, textarea, select{
          &.form-row--error{
            border:solid 2px #cc0000;
          }
        }
      }

      .button{
        @screen sm{
          width:100%;
          @apply py-3;
        }
      }
      @screen sm{
        @apply pb-8;
        @apply mb-2;
        border-bottom: solid 1px #eee;
      }
    }
  }
}
