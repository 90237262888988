@tailwind base;
@tailwind components;
@import '~@glidejs/glide/src/assets/sass/glide.core';
@import "~noty/src/noty.scss";
@import "~noty/src/themes/sunset.scss";
@import './global/base.scss';
@import './global/navigation.scss';
@import './global/footer.scss';

@import './pages/home.scss';
@import './pages/contact.scss';
@import './pages/batlist.scss';
@import './pages/stats.scss';
@import './pages/announcements.scss';
@tailwind utilities;
