.bat-list-block{
  @apply mb-10;
  .bat-list{
    &.allow-flow-1{
      -webkit-column-count: 4;
      -moz-column-count: 4;
      -ms-column-count: 4;
      -o-column-count: 4;
      column-count: 4;
      &.cols-2{
        column-count: 2;
      }
      &.cols-3{
        column-count: 3;
      }
      @screen sm{
        column-count: 2;
      }
    }

    @apply mt-6;
    @apply block;

    .keeptogether{
      display: inline-block;
    }
    ul{

      li{
        list-style: circle;

      }
    }
    .brand-name{
      @apply font-bold;
      list-style: none;
      break-after: avoid;
      break-after: avoid-column;
      break-after: avoid-region;
      @apply text-lg;
      @apply -ml-8;


    }
    &.allow-flow-0{
      @apply w-full;
      @apply flex;
      flex-wrap: wrap;
      ul{
        display: inline-block;
        ul{
          @apply w-1/4;
          @screen sm{
            @apply w-1/2;
          }
        }
      }
      &.cols-2{
        ul{
          @apply w-1/2;
          @screen sm{
            @apply w-1/2;
          }
        }
      }
      &.cols-3{
        ul{
          @apply w-1/3;
          @screen sm{
            @apply w-1/2;
          }
        }
      }

    }
  }

}
