*{
  @apply antialiased;
}

body{
  background-color: #fff;
  font-family: 'Oswald', ;
  @apply text-gray-900;
  background-color: #1a1b43;
  background-image: url("data:image/svg+xml,%3Csvg width='120' height='120' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0h2v20H9V0zm25.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm-20 20l1.732 1-10 17.32-1.732-1 10-17.32zM58.16 4.134l1 1.732-17.32 10-1-1.732 17.32-10zm-40 40l1 1.732-17.32 10-1-1.732 17.32-10zM80 9v2H60V9h20zM20 69v2H0v-2h20zm79.32-55l-1 1.732-17.32-10L82 4l17.32 10zm-80 80l-1 1.732-17.32-10L2 84l17.32 10zm96.546-75.84l-1.732 1-10-17.32 1.732-1 10 17.32zm-100 100l-1.732 1-10-17.32 1.732-1 10 17.32zM38.16 24.134l1 1.732-17.32 10-1-1.732 17.32-10zM60 29v2H40v-2h20zm19.32 5l-1 1.732-17.32-10L62 24l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM111 40h-2V20h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zM40 49v2H20v-2h20zm19.32 5l-1 1.732-17.32-10L42 44l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM91 60h-2V40h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM39.32 74l-1 1.732-17.32-10L22 64l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM71 80h-2V60h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM120 89v2h-20v-2h20zm-84.134 9.16l-1.732 1-10-17.32 1.732-1 10 17.32zM51 100h-2V80h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM100 109v2H80v-2h20zm19.32 5l-1 1.732-17.32-10 1-1.732 17.32 10zM31 120h-2v-20h2v20z' fill='%230d0e22' fill-opacity='0.28' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.hero{
  margin-top:-6rem;
  @apply bg-black;
  @screen sm{
    margin-top:0;
  }
  @apply w-full;
  // background-image: url(https://images.unsplash.com/photo-1528291954423-c0c71c12baeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3401&q=80);
    background-size: stretch;
  height: 25vh;
  min-height: 200px;

  @screen sm{
    height: 15vh;
    min-height: 130px;

  }
  .hero-container{
    height: 25vh;
    min-height: 200px;

    @screen sm{
      height: 15vh;
      min-height: 130px;
    }
    @apply relative;
    .hero-title{
      @apply relative;
      @apply z-30;
      @apply text-center;
      @apply w-full;
      padding-top: 96px;
      @apply text-white;
      @apply text-5xl;
      @screen sm{
        padding-left: 0;
        padding-top: 0px;
        @apply text-3xl;
      }
      &.abv{
        @apply text-4xl;
        @screen sm{
          padding-left: 0;
          padding-top: 0px;
          @apply text-base;
        }
        h4{
          @apply text-xl;
          @screen sm{
            @apply text-sm;
          }
        }
      }
    }


  }
  &.large, .slide-container{
    height: 60vh;
    min-height: 460px;
    .hero-container{
      height: 60vh;
      min-height: 460px;
    }
    @screen sm{
      height: 50vh;
      min-height: 360px;
      .hero-container{
        height: 50vh;
        min-height: 360px;
      }
    }
  }
  .image-container{
    @apply absolute;
    @apply block;
    @apply left-0;
    @apply top-0;
    @apply w-full;
    @apply bottom-0;
    @apply bg-cover;
    @apply z-10;
    opacity: 0.3;
    background-position: center center;
  }
  .slide-container{
    @apply bg-black;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    @apply relative;
    @apply flex;
    @apply px-12;
    overflow: hidden;
    @screen sm{
      @apply px-6;
    }
    &:active{
      cursor: grabbing;
    }

    .slide-content{
      @apply w-full;
      @apply flex;
      @apply items-center;
      @apply flex-1;
      @apply relative;
      @apply z-20;
      .slide-animate{
        transform: translateX(-100%);
      }
      .slide-content-container{
        padding-left: 140px;
        @apply w-full;

        @screen sm{
          padding-left: 0;
        }
        h2{
          @apply text-white;
          @apply w-full;
          font-size: 60px;
          line-height: 60px;
          @apply font-bold;
          @apply uppercase;
          @screen sm{
            font-size: 35px;
            margin-top: 4px;
            line-height: 35px;
          }
        }
        h3{
          @apply text-yellow-500;
          font-size: 20px;
          @screen sm{
            font-size: 16px;
          }
          @apply uppercase;
        }
        .button{
          @apply mt-6;
          @apply inline-block;
          @screen sm{
            @apply mt-4;
          }
        }
      }

    }
  }

}
.noty_theme__sunset.noty_bar .noty_body{
  @apply text-xl;
}
.loading-container{
  position: absolute;
  left:0;
  top:0;
  right:0;
  bottom:0;
  width:100%;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  svg{
    width:57px;
    height:60px;
  }
}
form.loading{
  input, label, button, p, textarea, select{
    opacity:0.4;
  }
}
form{
  input{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
input[type=button]{
  cursor: pointer;
}
.button{
  font-family: 'Merriweather', ;
  @apply bg-yellow-500;
  @apply px-8;
  @apply py-4;
  @apply text-black;
  @apply font-bold;
  transition: all .2s ease-in-out;
  cursor:pointer;
  @screen sm{
    @apply px-4;
    @apply py-2;
    @apply text-sm;
  }
  &:hover{
    // @apply bg-yellow-600;
    transform: scale(1.03);
  }
}
.glide__bullets{
  bottom:20px;
  width:100%;
  text-align: center;
  .glide__bullet{
    @apply w-3;
    @apply h-3;
    @apply mx-1;
    @apply bg-gray-200;
    opacity: 0.4;
    border-radius: 50%;
    &--active{
      opacity:0.9;
    }
  }
}
.signup-block{
  @apply w-full;
  .signup-content{
    @apply px-12;
    @apply py-8;
    @apply text-black;
    @apply text-center;
    h3{
      @apply text-xl;
      @apply font-bold;
    }
    p{
      @apply text-lg;
    }
    form{
      @apply mt-6;
      p{
        vertical-align: text-bottom;
      }
      input[type=email]{
        border: solid 2px #000;
        @apply bg-yellow-500;
        @apply text-black;
        height:50px;
        @apply text-xl;
        @apply px-6;
        outline: none;
        @apply inline-block;
        @screen sm{
          @apply w-full;
        }
      }
      input[type=button]{
        height:50px;
        @apply bg-black;
        @apply text-white;
        @apply px-6;
        cursor: pointer;
        transform: translateY(-1px);
        @apply inline-block;
        @screen sm{
          @apply mt-2;
          @apply w-full;
        }
      }
      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        @apply text-black;
        opacity: 0.7; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        @apply text-gray-900;
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        @apply text-gray-900;
      }
    }
  }
}
.grid-blue{
  background-color: #282965;
  background-image: url("data:image/svg+xml,%3Csvg width='120' height='120' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0h2v20H9V0zm25.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm-20 20l1.732 1-10 17.32-1.732-1 10-17.32zM58.16 4.134l1 1.732-17.32 10-1-1.732 17.32-10zm-40 40l1 1.732-17.32 10-1-1.732 17.32-10zM80 9v2H60V9h20zM20 69v2H0v-2h20zm79.32-55l-1 1.732-17.32-10L82 4l17.32 10zm-80 80l-1 1.732-17.32-10L2 84l17.32 10zm96.546-75.84l-1.732 1-10-17.32 1.732-1 10 17.32zm-100 100l-1.732 1-10-17.32 1.732-1 10 17.32zM38.16 24.134l1 1.732-17.32 10-1-1.732 17.32-10zM60 29v2H40v-2h20zm19.32 5l-1 1.732-17.32-10L62 24l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM111 40h-2V20h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zM40 49v2H20v-2h20zm19.32 5l-1 1.732-17.32-10L42 44l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM91 60h-2V40h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM39.32 74l-1 1.732-17.32-10L22 64l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM71 80h-2V60h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM120 89v2h-20v-2h20zm-84.134 9.16l-1.732 1-10-17.32 1.732-1 10 17.32zM51 100h-2V80h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM100 109v2H80v-2h20zm19.32 5l-1 1.732-17.32-10 1-1.732 17.32 10zM31 120h-2v-20h2v20z' fill='%23141432' fill-opacity='0.28' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.grid-blue-dark{
  background-color: #1a1b43;
  background-image: url("data:image/svg+xml,%3Csvg width='120' height='120' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0h2v20H9V0zm25.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm-20 20l1.732 1-10 17.32-1.732-1 10-17.32zM58.16 4.134l1 1.732-17.32 10-1-1.732 17.32-10zm-40 40l1 1.732-17.32 10-1-1.732 17.32-10zM80 9v2H60V9h20zM20 69v2H0v-2h20zm79.32-55l-1 1.732-17.32-10L82 4l17.32 10zm-80 80l-1 1.732-17.32-10L2 84l17.32 10zm96.546-75.84l-1.732 1-10-17.32 1.732-1 10 17.32zm-100 100l-1.732 1-10-17.32 1.732-1 10 17.32zM38.16 24.134l1 1.732-17.32 10-1-1.732 17.32-10zM60 29v2H40v-2h20zm19.32 5l-1 1.732-17.32-10L62 24l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM111 40h-2V20h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zM40 49v2H20v-2h20zm19.32 5l-1 1.732-17.32-10L42 44l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM91 60h-2V40h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM39.32 74l-1 1.732-17.32-10L22 64l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM71 80h-2V60h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM120 89v2h-20v-2h20zm-84.134 9.16l-1.732 1-10-17.32 1.732-1 10 17.32zM51 100h-2V80h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM100 109v2H80v-2h20zm19.32 5l-1 1.732-17.32-10 1-1.732 17.32 10zM31 120h-2v-20h2v20z' fill='%230d0e22' fill-opacity='0.28' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.hideout-yellow{
  background-color: #ffe401;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%234d4400' fill-opacity='0.18'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.architect-yellow{
  background-color: #ffe401;
background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23998901' fill-opacity='0.32' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}
.content{
  @apply px-12;
  @apply py-12;
  @apply bg-white;
  @screen sm{
    @apply px-4;
    @apply py-6;
  }
  .content-header{
    @apply text-2xl;
    @apply text-center;
    @apply pb-12;
    @screen sm{
      @apply pb-4;
      @apply text-xl;
    }
  }
}
.body-format{
  .text-block{
    @apply mb-2;
  }
  h2{
    @apply text-xl;
    @apply mb-2;
    @apply mt-4;

  }
  h3{
    @apply text-lg;
    @apply my-2;
    font-family: 'Oswald', ;
    &.italic{
      @apply italic;
      @apply text-base;
    }
  }

  p{
    @apply mb-2;
  }
  ul{
    @apply block;
    @apply pl-8;
    @apply list-disc;
    li{
      @apply mb-1;
    }
  }
  table{
    tr{
      td{
        @apply text-sm;
        p{
          &:last-child{
            @apply mb-0;
          }
        }
      }
    }
  }
}
.container{
  &.form-small{
    max-width: 960px;
  }
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Merriweather', ;
  @apply font-bold;
}

.genericSelect{
  @apply relative;
  select{
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    @apply block;
    @apply mb-3;
    @apply px-6;
    @apply w-full;
    border: solid 2px #000;
    height:50px;
    border-radius: 0;
    background: #fff;
    @apply relative;
    &:invalid { color: gray; }
  }
  &:after {
    content: '';
    position: absolute;
    right: 15px;
    bottom:17px;
    width: 0px;
    height: 0px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid #000;
    clear: both;

  }
}
.filter-block{
  .grid{
    max-width: 800px;
  }
}
.loading-holder{
  @apply flex;
  @apply w-full;
  @apply justify-center;
  @apply items-center;
  svg{
    width:57px;
    height:60px;
  }
}
