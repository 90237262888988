.game-schedule{
  .games{
    .game{
      @apply justify-start;
      .team-container{

      }
      &.isFinal{

        .team-container{
          cursor: pointer;
          @apply text-blue-500;
          @apply border-b;
          @apply border-blue-500;
          @screen sm{
            border: none;
          }
        }
      }
    }
  }
}
.stat-table{
  th{
    text-align: center;
    @apply text-base;
    @apply px-4;
    @apply py-2;
    @apply border;
    @apply bg-gray-200;
  }
  td{
    text-align: center;
    @apply text-base;
    @apply px-4;
    @apply py-2;
    @apply border;
    &.text-left{
      @apply text-left;
    }
  }
  tr:nth-child(even) {
    @apply bg-gray-100;
  }
}
