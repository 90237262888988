.sub-nav{
  @apply h-10;
  @apply bg-blue-700;
  @apply px-12;
  @apply flex;
  @apply content-between;
  @apply justify-between;
  @screen sm{
    @apply hidden;
  }
  .sub-notification{
    @apply h-10;
    @apply inline-flex;
    @apply content-center;
    @apply items-center;
    span{
      @apply text-white;
      svg{
        @apply inline-block;
        @apply mr-2;
      }
      p{
        display: inline-block;
        a{
          text-decoration: underline;
        }
      }
    }
  }
  ul{
    @apply flex;
    @apply float-right;
    @apply mr-4;
    li{
      @apply block;
      a{
        @apply h-10;
        @apply inline-flex;
        @apply content-center;
        @apply items-center;
        @apply px-4;
        @apply text-white;
        @apply text-sm;
        transition: all .2s ease-in-out;
        &.current{
          @apply text-yellow-500;
        }
        &:hover{
          @apply text-yellow-500;
          transform: translateY(-3px);
        }
      }
    }
  }
}
.navigation{
  @apply w-full;
  @apply h-24;
  @apply z-50;
  @apply bg-black;
  // @apply bg-blue-590;
  background-color: rgba(44,45,112, .80);
  @apply px-12;
  @apply relative;
  @apply flex;
  @apply content-between;
  @apply justify-between;
  @screen sm{
    @apply h-20;
    @apply px-6;
    @apply bg-blue-500;
    @apply flex;
    @apply justify-end;
    @apply content-center;
    @apply items-center;
  }
  #navigationDropdown{
    z-index: 3;
  }
  .logo-cover{
    z-index: 2;
    @apply h-24;
    @screen sm{
      @apply h-20;
      @apply w-full;
      @apply grid-blue;
      @apply absolute;
      @apply right-0;
      @apply left-0;
    }
    a{
      @apply inline-block;
      img{
        @apply pt-4;
        width:120px;
      }
      @screen sm{
        @apply ml-4;
        img{
          width:60px;
          @apply pt-2;
        }
      }
    }
  }
  #navigationDropdown{
    @apply hidden;
    @screen sm{
      @apply block;
    }
  }
  ul#mainNavigation{
    @apply flex;
    @apply float-right;
    transition: all .3s ease-in-out;
    @screen sm{
      z-index: 1;
      transform: translateY(-100%);
      @apply right-0;
      // height: calc(100vh - 5rem);
      // @apply h-0;
      @apply w-full;
      @apply absolute;
      @apply block;
      @apply bg-blue-700;
      top:5rem;
      @apply py-4;

      a{
        @apply w-full;
      }
      // @apply top-16;
      &.active{
        @apply overflow-auto;
        @screen sm{
          overflow-y: scroll;
        }
        transform: translateY(0%);
      }
    }
    li{
      &.mobile-only{
        @apply hidden;
        @screen sm{
          @apply block;
        }
      }
      @apply block;
      @screen sm{
        @apply w-full;
      }
      a{
        @apply inline-flex;
        @apply px-8;
        @apply content-center;
        @apply items-center;
        @apply h-24;
        @screen sm{
          @apply h-12;
        }
        @apply text-white;
        transition: all .2s ease-in-out;
        &.current{
          background-color: #ffe401;
          background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23998901' fill-opacity='0.32' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
          @apply text-gray-900;
          @screen sm{
            @apply bg-blue-700;
            @apply text-yellow-500;

          }
        }

      }
      &:hover{
        // @apply bg-yellow-500;
        a{
          box-shadow: inset 0px -3px 0px 0px rgba(255,228,1,1);
          @screen sm{
            box-shadow: none;
          }
        }
      }
      ul{
        @apply h-0;
        @apply absolute;
        @apply bg-blue-700;
        @apply right-0;
        min-width: 200px;
        @apply shadow-md;
        @apply z-10;
        @apply bg-white;
        @apply overflow-hidden;
        @apply block;
        @apply opacity-0;
        transform: translateY(-20px);
         // animation-name: fadeInDown;
        transition: all .2s ease-in-out;
        @screen sm{
          @apply relative;
          @apply w-full;
          @apply top-0;
          transform: translateY(0px);
        }
        li{
          @apply w-full;
          a{
            @apply w-full;
            @apply h-16;
            @screen sm{
              @apply h-12;
            }
            box-shadow: none!important;
            @apply text-gray-800;
            &:hover{
              @apply bg-gray-200;
            }
          }
        }
      }

      &.with-sub-nav{
        @apply relative;
        svg{
          @apply w-4;
          @apply h-4;
          @apply text-white;
          @apply fill-current;
          @apply inline-block;
          @apply ml-2;
        }
        a.current{
          svg{
            @apply text-gray-900;
            @screen sm{
              @apply text-yellow-500;
            }
          }
        }
        @screen sm-up{
          &:hover{
            ul{
              @apply h-auto;
              transform: translateY(0);
              @apply opacity-100;
              // @apply left-0;
            }
          }
        }
        &.coming-soon{
          &:hover{
            ul{
              height:0!important;
            }
          }
          @screen sm{
            ul{
              @apply h-0;
              transform: translateY(0);
              @apply opacity-100;

            }
            &.expanded{
              ul{
                @apply h-0;
              }

            }
          }
        }
        @screen sm{
          ul{
            @apply h-0;
            transform: translateY(0);
            @apply opacity-100;

          }
          &.expanded{
            ul{
              @apply h-auto;
            }

          }
        }

      }
      &.coming-soon{
        @apply relative;
        &:hover{
          &:after{
            content:'Coming Soon';
            @apply items-center;
            @apply justify-center;
            position:absolute;
            width:100%;
            left:0;
            right:0;
            top:0;
            bottom:0;
            cursor:pointer;
            display: flex;
            @apply text-yellow-500;
            background-color: rgba(0,0,0,0.6);
          }
        }
      }
    }
  }
}
